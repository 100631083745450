<template>
    <CCard header="9.823" text="Jobs">
        <router-link :to="{name: 'jobs'}">
      <CCardHeader class="enrich-card">
        <CRow>
            <div>
            <CBadge color="info">In Progress {{inProgressCount}}</CBadge>
            </div>
        </CRow>
      </CCardHeader>
      <CCardBody class="enrich-card">
        <div class="text-center">
            <a-icon class="enrich-card-icon" type="solution" />
        </div>
      </CCardBody>
      <CCardFooter class="enrich-card" align="center">
            <h3>Jobs</h3>
      </CCardFooter>
        </router-link>
    </CCard>
</template>

<script>

import axios from 'axios'

export default {
    name: 'JobsWidget',
    data() {
        return {
            inProgressCount: 0,
            fetching: false
        }
    },
    methods: {
        fetchJobs () {
            console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
            const url = process.env.VUE_APP_CRM_API_ROOT + '/jobs' //?opportunityStatus=New';
            console.log('fetch jobs')
            this.fetching = true;

            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    this.error = err;
                    delete this.$http.defaults.headers.common['Authorization'];
                    this.fetching = false;
                } else {
                    console.log('Jobs, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    axios({ method: 'get', url: url})
                        .then(response => {
                            console.log('fetch jobs widget, response: ' + response)
                            let jobs = response.data
                            let inProgCount = 0
                            for(var i = 0; i < jobs.length; i++) {
                                if (jobs[i].jobStatus == 'In Progress') {
                                    inProgCount++
                                }
                            }
                            this.inProgressCount = inProgCount
                            this.fetching = false;
                        })
                        .then(status => {
                            console.log('fetch Jobs, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            this.fetching = false;
                            console.log('fetch Jobs, axios err: ' + err)
                            console.log('err properties...')
                            Object.keys(err).forEach((prop)=> console.log(prop));
                            this.error = err;
                            console.log('err.request properties...')
                            Object.keys(err.request).forEach((prop)=> console.log(prop));

                        })
                }
            })
        }
    },

    mounted () {
        console.log('Jobs:mounted')
        this.fetchJobs()
    }
}

</script>
