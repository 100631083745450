<template>
    <CCard header="9.823" text="Contacts">
        <router-link :to="{name: 'contacts'}">
      <CCardHeader class="enrich-card">
        <CRow>
            <div>
            <CBadge color="success"><a-icon type="contacts" /></CBadge>
            </div>
        </CRow>
      </CCardHeader>
      <CCardBody class="enrich-card">
        <div class="text-center">
            <a-icon class="enrich-card-icon" type="mail" />
            &nbsp;
            <a-icon class="enrich-card-icon" type="phone" />
        </div>
      </CCardBody>
      <CCardFooter class="enrich-card" align="center">
            <h3>Contacts</h3>
      </CCardFooter>
        </router-link>
    </CCard>
</template>

<script>

import axios from 'axios'

export default {
    name: 'ContactsWidget',
    data: function() {
        return {
        };
    },
    data() {
        return {
        }
    },
    methods: {
    },

    mounted () {
        console.log('Opportunities:mounted')

    }
}

</script>
