<template>
    <CCard header="9.823" text="Opportunities">
        <router-link :to="{name: 'opportunities'}">
      <CCardHeader class="enrich-card">
        <CRow>
            <div v-if="newOpportunitiesCount">
                <CBadge color="danger">New {{newOpportunitiesCount}}</CBadge>
            </div>
            &nbsp;
            <div>
            <CBadge color="info">Responded {{respondedOpportunitiesCount}}</CBadge>
            </div>
        </CRow>
      </CCardHeader>
      <CCardBody class="enrich-card">
        <div class="text-center">
            <a-icon class="enrich-card-icon" type="user" />
            &nbsp;
            <a-icon class="enrich-card-icon" type="question-circle" />
        </div>
      </CCardBody>
      <CCardFooter class="enrich-card" align="center">
            <h3>Opportunities</h3>
      </CCardFooter>
        </router-link>
    </CCard>
</template>

<script>

import axios from 'axios'

export default {
    name: 'OpportunitiesWidget',
    data() {
        return {
            opportunities: null,
            newOpportunitiesCount: 0,
            respondedOpportunitiesCount: 0,
            fetching: true
        }
    },
    methods: {
        fetchOpportunities () {
            console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
            const url = process.env.VUE_APP_CRM_API_ROOT + '/opportunities' //?opportunityStatus=New';
            // const params = {"opportunityStatus" : "New"}
            console.log('fetch opportunities')
            this.fetching = true;

            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    this.error = err;
                    delete this.$http.defaults.headers.common['Authorization'];
                    this.fetching = false;
                } else {
                    console.log('Opportunities, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    axios({ method: 'get', url: url})
                        .then(response => {
                            console.log('fetch opportunities widget, response: ' + response)
                            let opportunities = response.data
                            let newCount = 0
                            let respondedCount = 0
                            for(var i = 0; i < opportunities.length; i++) {
                                if (opportunities[i].opportunityStatus == 'New') {
                                    newCount++
                                }
                                if (opportunities[i].opportunityStatus == 'Responded') {
                                    respondedCount++
                                }
                            }
                            this.newOpportunitiesCount = newCount
                            this.respondedOpportunitiesCount = respondedCount
                            this.fetching = false;
                        })
                        .then(status => {
                            console.log('fetch opportunities, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            this.fetching = false;
                            console.log('fetch opportunities, axios err: ' + err)
                            console.log('err properties...')
                            Object.keys(err).forEach((prop)=> console.log(prop));
                            this.error = err;
                            console.log('err.request properties...')
                            Object.keys(err.request).forEach((prop)=> console.log(prop));

                        })
                }
            })
        }
    },

    mounted () {
        console.log('Opportunities:mounted')
        this.fetchOpportunities()


    }
}

</script>
