<template>
  <CRow class="d-flex align-items-stretch">
    <CCol sm="6" lg="3">
      <opportunitiesWidget/>
    </CCol>
    <CCol sm="6" lg="3">
      <newOpportunityWidget/>
    </CCol>
    <CCol sm="6" lg="3">
      <contactsWidget/>
    </CCol>
    <CCol sm="6" lg="3">
      <jobsWidget/>
    </CCol>
  </CRow>
</template>

<script>
import OpportunitiesWidget from './widgets/OpportunitiesWidget.vue'
import NewOpportunityWidget from './widgets/NewOpportunityWidget.vue'
import JobsWidget from './widgets/JobsWidget.vue'
import ContactsWidget from './widgets/ContactsWidget.vue'
import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'DashboardWidgets',
  components: {
    OpportunitiesWidget,
    ContactsWidget,
    NewOpportunityWidget,
    JobsWidget,
    CChartLineSimple, CChartBarSimple
  }
}
</script>


