<template>
  <div>
    <DashboardWidgets/>
  </div>
</template>

<script>
import DashboardWidgets from './DashboardWidgets'

export default {
  name: 'Dashboard',
  components: {
    DashboardWidgets
  },
  data () {
    return {
      selected: 'Month',
    }
  },
  methods: {
  }
}
</script>
